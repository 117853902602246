<template>
  <div style="background: #F7F9FC;color: #000;height: 100%;">
    <van-nav-bar :title="isok == 1 ? '接车详情' : '交车详情'" left-arrow :fixed="true" :z-index="999" @click-left="back">
      <template #right>
        <img @click="dingwei" class="dakaimg" src="../../../assets/img/daka.png" alt="">
        <van-icon v-if="isok == 2" name="column" @click="tolist" color="#33a3dc" size="26" />
      </template>
    </van-nav-bar>

    <div class="hestou" style>
      <div class="h-100">
        <div class="h-100 o-y-auto" style="background: #fafafa;padding-bottom:48px;">
          <van-row class="hedrowtop">
            <van-col span="8">业务来源：</van-col>
            <van-col span="16" class="ris">{{ orderData.source }}</van-col>
          </van-row>
          <van-row class="hedrowtop">
            <van-col span="8">车牌：</van-col>
            <van-col span="16" class="ris">{{ orderData.carNo }}</van-col>
          </van-row>
          <van-row class="hedrowtop">
            <van-col span="8">车型：</van-col>
            <van-col span="16" class="ris">{{ orderData.carBrand }}-{{ orderData.carModel }}</van-col>
          </van-row>
          <!-- <van-row class="hedrowtop">
            <van-col span="8">
              <div class="dis">接车日期：</div>
            </van-col>
            <van-col span="16" class="ris">
              {{ orderData.createdDate ? orderData.createdDate.substring(0, 16) : '' }}</van-col>
          </van-row> -->
          <van-row class="hedrowtop">
            <van-col span="8">
              <div class="dis">接车时间：</div>
            </van-col>
            <van-col span="16" class="ris">
              {{ orderData.createdDate ? orderData.createdDate.substring(0, 16) : '无' }}</van-col>
          </van-row>

          <van-row class="hedrowtop">
            <van-col span="8">
              <div class="dis" @click="getlocationamend">要求交车日期：</div>
            </van-col>
            <van-col span="16" class="ris">
              {{ orderData.completeTime ? orderData.completeTime.substring(0, 16) : '无' }}</van-col>
          </van-row>
          <van-row class="hedrowtop">
            <van-col span="8">
              <div class="dis">交车事项：</div>
            </van-col>
            <van-col span="16" class="ris">
              {{ orderData.description ? orderData.description : '无' }}</van-col>
          </van-row>
          <van-row class="hedrow" style="border-top: 4px solid #f5f5f5;">
            <van-col span="12" style="border-left: 4px solid #2A9F58;padding-left: 5px">项目名称</van-col>

            <van-col span="4" class="cet">数量</van-col>
            <van-col span="4" class="cet">单价</van-col>
            <van-col span="4" class="cet">金额</van-col>
          </van-row>
          <van-row class="hedrow" style="border-top: 1px solid #f5f5f5;" v-for="(item, index) in orderData.orderItemList"
            :key="index">
            <van-col span="12" style="padding-left: 5px">{{ item.itemName }}</van-col>
            <van-col span="4" class="cet">{{ item.counts }}</van-col>
            <van-col span="4" class="cet">{{ item.standPrice }}</van-col>
            <van-col span="4" class="cet">{{ item.counts * item.standPrice }}</van-col>
          </van-row>
          <!-- 					<van-row class="hedrow" style="border-top: 4px solid #f5f5f5;" v-if="pjisok == 'true'">
						<van-col span="8" style="border-left: 4px solid #2A9F58;padding-left: 5px">配件费</van-col>
						<van-col span="4" class="cet">单价</van-col>
						<van-col span="4" class="cet">数量</van-col>
						<van-col span="4" class="cet">券/折减</van-col>
						<van-col span="4" class="cet">金额</van-col>
					</van-row>
					<div class="hedrows" v-for="(item, indexs) in orderData.orderItemList" :key="'i' + indexs"
						v-if="item.orderPartList.length > 0">
						<van-row v-for="(item2, index2) in item.orderPartList" :key="index2"
							style="border-top: 1px solid #f5f5f5;">
							<van-col span="8" style="padding-left: 5px">{{ item2.partName }} {{ index2 }}</van-col>
							<van-col span="4" class="cet">{{ item2.price ? item2.price.toFixed(2) : '0.0' }}</van-col>
							<van-col span="4" class="cet">{{ item2.counts }}</van-col>
							<van-col span="4" class="cet">
								{{ item2.couponDiscount ? item2.couponDiscount.toFixed(2) : '0.00' }}
							</van-col>
							<van-col span="4" class="cet">￥{{ (item2.price * item.counts) }}</van-col>
						</van-row>
					</div> -->

          <van-row class="hedrow" style="border-top: 4px solid #f5f5f5;">
            <van-col span="24">
              <van-col span="12" style="padding-left: 5px">合计:</van-col>
              <van-col span="4" class="cet">{{ numheji }}</van-col>
              <van-col span="4" class="cet"></van-col>
              <van-col span="4" class="cet">{{ heji }}</van-col>
            </van-col>
          </van-row>
          <div class="dds" v-if="orderData.clerkOrderLogDO && isok == 2">
            <div>【{{ isok == 1 ? orderData.clerkOrderLogDO.clerkOrder : orderData.clerkOrderLogDO.name }}】已与{{ isok ==
              1 ? orderData.clerkOrderLogDO.clerkDate : orderData.clerkOrderLogDO.date }}{{ isok == 1 ? '确认' : '交车' }}
            </div>
            <div class="yidao" style="width: 50%;" v-if="isok == 1">已确认</div>
            <div class="yidao" style="width: 50%;" v-if="isok == 2">已交车</div>
          </div>
        </div>
      </div>
      <div style="height: 120px;"></div>
      <div class="bottts" v-if="!orderData.clerkOrderLogDO">
        <!-- <div class="mend" v-if="shangjia && isok != 2">
          <div class="">
            门店：<span style="color: #2a9f58;">{{ shangjia.name }}</span>
          </div>
          <div @click="toXz">
            选择>
          </div>
        </div> -->
        <div v-if="isok == 1" class="flxs">
          <div style="flex: 1;">
            <div class="wgdiv ">
              <div class="wgb">外观视频</div>

            </div>
            <div class="my_play">
              <img v-if="gdimform.videoUrl != ''" @click="showvideo = true" src="../../../assets/img/bof.jpg" alt="">
              <img v-else src="../../../assets/img/spb.jpg" alt="">
              <!-- <video v-if="gdimform.videoUrl != ''" @click="showvideo = true"  :src="gdimform.videoUrl">

              </video> -->

              <!-- <video-player controls="controls" @play="onplayFn($event)" @pause="onpauseFn($event)"
                @ended="onendedFn($event)" class="video-player vjs-custom-skin" :options="playerOptions"></video-player> -->
            </div>


          </div>
          <div style="flex: 1;">
            <div class="wgdiv">
              <div class="wgb">仪表盘</div>
            </div>
            <img @click="toimgdan(gdimform.miles)" v-if="gdimform.miles != ''" :src="gdimform.miles" alt="">
            <img v-else src="../../../assets/img/spa.png" alt="">
          </div>
        </div>
        <div class="tyi" style="width: 50%;" @click="toJieChe" v-if="isok == 1">确认无其他问题</div>
        <div class="tyi" @click="showmd = true" v-if="isok == 2">施工无质量问题，同意交车</div>
      </div>
      <div class="bottts" v-if="orderData.clerkOrderLogDO && isok == 1">
        <div v-if="isok == 1" class="flxs">
          <div style="flex: 1;">
            <div class="wgdiv ">
              <div class="wgb">外观视频</div>

            </div>
            <div class="my_play">
              <img v-if="gdimform.videoUrl != ''" @click="showvideo = true" src="../../../assets/img/bof.jpg" alt="">
              <img v-else src="../../../assets/img/spb.jpg" alt="">
            </div>
          </div>
          <div style="flex: 1;">
            <div class="wgdiv">
              <div class="wgb">仪表盘</div>
            </div>
            <img @click="toimgdan(gdimform.miles)" v-if="gdimform.miles != ''" :src="gdimform.miles" alt="">
            <img v-else src="../../../assets/img/spa.png" alt="">
          </div>
        </div>
        <div v-if="isok == 1"  class="jsnames">
          <van-icon name="checked" color="#16b766" size="50" ></van-icon>
          <div>【{{ orderData.clerkOrderLogDO.clerkOrder }}】{{ orderData.clerkOrderLogDO.clerkDate? orderData.clerkOrderLogDO.clerkDate.substring(5, 16) : '无' }}
            </div>
        </div>
      </div>
      <div class="botdunw">
        <div class="diwdiv" v-if="dwform.saddress && isok == 2">
          <img src="../../../assets/img/dws.png" alt="">
          <span>{{ dwform.sname }}</span>
          <span>{{ dwform.lastModifiedDate.substring(5, 16) }}</span>
          <span>{{ dwform.saddress }}</span>
        </div>
        <div class="diwdiv" v-if="dwform.jaddress && isok == 1">
          <img src="../../../assets/img/dws.png" alt="">
          <span>{{ dwform.jname }}</span>
          <span>{{ dwform.createdDate.substring(5, 16) }}</span>
          <span>{{ dwform.jaddress }}</span>
        </div>
      </div>
    </div>
    <van-popup class="fixtan" v-model="show">
      <van-nav-bar :left-arrow="tanshow" @click-left="show = false" title="请先选择门店" :fixed="true" :z-index="999" />
      <van-search v-model="name" show-action placeholder="请输入门店搜索关键词" @search="onSearch">
        <template #action>
          <div @click="onSearch" style="color: #0d906e;">搜索</div>

        </template>
      </van-search>

      <div class="mian" :style="{ height: windowHeight + 'px' }" @scroll="scrollGet($event)">
        <div class="foot_text" v-for="(item, idx) in certsList" :key="idx">
          <div>{{ item.name }}</div>
          <div style="color: #07c160;" @click="tomengdian(item.id)">选择</div>
        </div>
      </div>
    </van-popup>
    <van-dialog v-model="showmd" title="提示" show-cancel-button @confirm="setFZ">
      <div class="hs" v-if="isok == 1">确认施工项目无问题？</div>
      <div class="hs" v-if="isok == 2">确认无质量问题，同意接车？</div>
    </van-dialog>
    <van-popup style="width: 100%;" v-model="showvideo" @close="guanbi">
      <video-player ref="videoPlayer" controls="controls" class="video-player vjs-custom-skin"
        :options="playerOptions"></video-player>
    </van-popup>
  </div>
</template>

<script>
import {
  getDetailByOId,
  garageAll,
  getGarageInfo,
  checkAuth,
  orderCopyShareOrder,
  config,
  successClerk,
  bpdrop,
  bppick,
  getbporder,
  picdetails
} from "@/api/check";
import { ImagePreview } from "vant";
import { jsonp } from "vue-jsonp";
import preventReClick from "@/assets/preventReClick";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  //   mixins: [preventBack], //注入
  components: {},
  data() {
    return {
      list: [],
      show: false,
      checked: false,
      showvideo: false,
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      orderData: {},
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], //可选择的播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。


        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "",
            src: "http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4" //url地址
          }
        ],
        poster: "", //你的封面地址
        // width: document.documentElement.clientWidth,
        // notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, //当前时间和持续时间的分隔符
          durationDisplay: true, //显示持续时间
          remainingTimeDisplay: true, //是否显示剩余时间功能
          fullscreenToggle: true //全屏按钮
        }
      },
      form: {},
      dwform: {},
      loading: false,
      showmd: false,
      isok: null,
      tanshow: false,
      total: 0,
      finished: false,
      name: "",
      heji: 0,
      pjisok: "true",
      payType: 1, //1微信支付 2储值卡支付
      offset: 0,
      certsList: [],
      active: "0",
      oid: this.$route.query.oid,
      gid: this.$route.query.gid,
      denglu: false,
      numheji: 0,
      formName: {},
      gdimform: {
        miles: '',
        videoUrl: '',
      },
      latitude: null,
      longitude: null,
      windowHeight: document.documentElement.clientHeight - 130,
      page: 0,
      size: 20,
    };
  },
  methods: {

    toimgdan(img) {
      this.$router.push({
        name: "showimg",
        query: {
          img: img,
          idx: 0,
        },
      });
    },
    guanbi() {
      console.log('sdadsa')
      this.$refs.videoPlayer.player.pause()
    },


    getConfig() {
      let that = this;
      let userAgent = navigator.userAgent;
      let shareUrl = "";
      if (userAgent.includes("iPhone") || userAgent.includes("iPad")) {
        shareUrl = sessionStorage.getItem("originUrl");
      } else {
        shareUrl = location.href;
      }
      config({
        url: shareUrl,
        gid: sessionStorage.getItem("Agid")
          ? sessionStorage.getItem("Agid")
          : 208,
      })
        .then((res) => {
          if (res.code == 200) {
            console.log(res);
            wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: res.data.appid, // 必填，公众号的唯一标识
              timestamp: res.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: res.data.noncestr, // 必填，生成签名的随机串
              signature: res.data.signature, // 必填，签名
              jsApiList: [
                "getLocation"
              ], // 必填，需要使用的JS接口列表
            });

          } else {
            this.$toast(res.data.msg);
          }
        });
    },
    dingwei() {
      var _this = this
      wx.getLocation({//获取位置信息方法一定要放在wx.ready中调用
        type: 'gcj02', // 默认为wgs84的 gps 坐标，如果要返回直接给 openLocation 用的火星坐标，可传入'gcj02'；调试过程中发现wgs84获取的位置会偏移几百米左右
        isHighAccuracy: true,
        success: function (res) {
          _this.latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
          _this.longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
          var speed = res.speed; // 速度，以米/每秒计
          var accuracy = res.accuracy; // 位置精度
          console.log(res)
          _this.getlocationamend()
        },
        fail: function (res) {
          console.log('error:', res)
        },
      });

    },
    onSearch() {
      this.page = 0;
      this.finished = false;
      this.certsList = [];
      this.getaccountList();
    },
    getaccountList() {
      var data = {
        name: this.name,
        page: this.page,
        size: this.size,
      };
      garageAll(data).then((response) => {
        let rows = response.data.content;
        this.loading = false;
        this.total = response.data.totalElements;
        if (rows == null || rows.length === 0) {
          // 加载结束
          this.finished = true;
          return;
        }
        // 将新数据与老数据进行合并
        this.certsList = this.certsList.concat(rows);
        //如果列表数据条数>=总条数，不再触发滚动加载
        if (this.certsList.length >= this.total) {
          this.finished = true;
        }
        this.page++;
      });
    },
    setFZ() {
      var _this = this;
      if (this.isok == 1) {
        var data = {
          oid: this.oid,
          clerkOrder: this.userInfo.realName,
          type: 1
        };
        successClerk(data).then((e) => {
          if (e.code == 200) {
            this.showmd = false;
            this.$toast.success("提交成功");
            setTimeout(() => {
              _this.onLoad();
            }, 1000);
          }
        });
      } else if (this.isok == 2) {
        var data = {
          oid: this.oid,
          clerkOrder: this.userInfo.realName,
          type: 0
        };
        successClerk(data).then((e) => {
          if (e.code == 200) {
            this.showmd = false;
            this.$toast.success("提交成功");
            setTimeout(() => {
              _this.onLoad();
            }, 1000);
          }
        });
      }
    },
    tolist() {
      this.$router.push({
        path: "/sharing_list",
        query: {
          gid: this.gid,
          source: this.orderData.source,
        },
      });
    },
    toJieChe() {
      this.showmd = true;
      // const loading = this.$toast.loading({
      //   mask: true,
      //   duration: 0,
      //   message: "加载中...",
      // });
      // checkAuth({
      //   gid: this.shangjia.id,
      // })
      //   .then((e) => {
      //     loading.clear();
      //     // console.log(e.data)
      //     if (e.data) {
      //       this.formName = e.data;

      //     } else {
      //       this.$toast("您不是本店员工");
      //     }
      //   })
      //   .catch();
    },
    toXz() {
      this.tanshow = true;
      this.show = true;
    },
    tomengdian(id) {
      var url =
        this.$route.path +
        "?gid=" +
        this.gid +
        "&oid=" +
        this.oid +
        "&isok=" +
        this.isok +
        "&denglu=" +
        "true";
      localStorage.setItem("beforeUrl", url);
      sessionStorage.setItem("Agid", this.gid);
      sessionStorage.setItem("gid", this.gid);
      sessionStorage.setItem("oid", this.$route.query.oid);

      getGarageInfo({
        gid: id,
      })
        .then((e) => {
          localStorage.setItem("JCnewgid", id);
          // loading.clear();
          this.shangjia = e.data;
          sessionStorage.setItem("appid", e.data.idd);
          sessionStorage.setItem("shangjia", JSON.stringify(this.shangjia));
          this.show = false;

          // 正式
          const redirect_uri = encodeURIComponent(
            "https://shopping.car-posthouse.cn/auths"
          );

          window.location.href = `https://marketing.car-posthouse.cn/get-weixin-code.html?appid=${e.data.idd}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
        })
        .catch(() => loading.clear());
    },
    scrollGet(e) {
      //滚动的像素+容器的高度>可滚动的总高度-100像素
      // console.log(e.srcElement.scrollTop + e.srcElement.offsetHeight)
      // console.log('rr'+e.srcElement.scrollHeight)
      if (
        e.srcElement.scrollTop + e.srcElement.offsetHeight >=
        e.srcElement.scrollHeight - 1
      ) {
        if (this.total > this.certsList.length) {
          this.getaccountList(); //加载更多
        }
        // console.log('rr')
      }
    },
    // onLoads() {
    //     this.getaccountList()
    // },

    //点击放大图片
    getImg(images) {
      ImagePreview([images]);
    },
    getdingweixinxi() {
      getbporder({ oid: this.oid })
        .then((e) => {
          if (e.code == 200) {
            this.dwform = e.data
          }

        })
    },
    onLoad() {
      var _this = this;
      const data = {
        gid: this.gid,
        oid: this.oid,
      };
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中...",
      });
      getDetailByOId(data)
        .then((e) => {
          loading.clear();
          this.getdingweixinxi()
          this.orderData = e.data;
          var orderItemList = e.data.orderItemList;
          var orderPartList = [];
          var numheji = 0;
          var heji = 0;
          for (var i in orderItemList) {
            numheji += orderItemList[i].counts;
            heji += orderItemList[i].counts * orderItemList[i].standPrice;
            for (var j in orderItemList[i].orderPartList) {
              orderPartList.push(orderItemList[i].orderPartList[j]);
            }
          }
          this.numheji = numheji;
          this.heji = heji;
          // if (this.isok != 2) {
          //   if (
          //     !this.orderData.clerkOrderLogDO &&
          //     this.denglu != "true" &&
          //     !localStorage.getItem("JCnewgid")
          //   ) {
          //     _this.show = true;
          //   }
          // }

          if (orderPartList.length > 0) {
            _this.pjisok = "true";
          } else {
            _this.pjisok = "false";
          }
        })
        .catch(() => loading.clear());
    },
    getpic() {
      picdetails({ oid: this.oid })
        .then((e) => {
          if (e.code == 200) {
            this.gdimform = e.data
            this.playerOptions.sources[0].src = e.data.videoUrl
          }

        })
    },
    //获取地址修改地址信息
    getlocationamend() {
      var _this = this;
      var latitude = Number(_this.latitude);
      var longitude = Number(_this.longitude);
      // var latitude = Number(22.905906677246094);
      // var longitude = Number(114.02117156982422);
      const params = {
        // 以下三个参数为 调通接口必填的参数，少一个则会报错
        output: "jsonp",
        location: latitude + "," + longitude,
        key: "SX4BZ-NJ2CR-5GPW4-WFOH6-2ERHS-6GFK7", //你自己申请的key
      };
      let url = `https://apis.map.qq.com/ws/geocoder/v1`;
      jsonp(url, params)
        .then((res) => {
          console.log(res);
          var address = res.result.address_reference.town.title + res.result.address_component.street_number


          // _this.locationform = res.result.ad_info;
          if (_this.isok == 1) {
            bppick({ oid: _this.oid, address: address })
              .then((e) => {
                if (e.code == 200) {
                  _this.$toast.success("定位成功");
                  _this.getdingweixinxi()
                }

              })
          } else {
            bpdrop({ oid: _this.oid, address: address })
              .then((e) => {
                if (e.code == 200) {
                  _this.$toast.success("定位成功");
                  _this.getdingweixinxi()
                }

              })
          }

        })
        .catch((err) => {
          console.log("err", err);
        });
    },

    back() {
      parent.WeixinJSBridge.call("closeWindow");
    },
  },
  created() {
    var url =
      this.$route.path +
      "?gid=" +
      this.gid +
      "&oid=" +
      this.oid +
      "&isok=" +
      this.isok;
    localStorage.setItem("beforeUrl", url);
    this.isok = this.$route.query.isok;
    this.denglu = this.$route.query.denglu;
    // return

    var url =
      this.$route.path +
      "?gid=" +
      this.gid +
      "&oid=" +
      this.oid +
      "&isok=" +
      this.isok +
      "&denglu=" +
      "true";
    localStorage.setItem("beforeUrl", url);
    sessionStorage.setItem("Agid", this.gid);
    sessionStorage.setItem("gid", this.gid);
    sessionStorage.setItem("oid", this.$route.query.oid);
    getGarageInfo({
      gid: sessionStorage.getItem("gid"),
    })
      .then((e) => {
        // loading.clear();
        this.shangjia = e.data;
        sessionStorage.setItem("appid", e.data.idd);
        sessionStorage.setItem("shangjia", JSON.stringify(this.shangjia));
        document.title = e.data.name;
        if (localStorage.getItem('Authorization')) {
          this.getConfig()
          this.onLoad();
          this.getpic()
        } else {
          // 正式
          const redirect_uri = encodeURIComponent(
            "https://shopping.car-posthouse.cn/auths"
          );
          window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${e.data.idd}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
          return;
        }
        return;
      })
      .catch(() => loading.clear());


  },
  mounted() {
    this.getaccountList();
  },
  computed: {

  },

};
</script>

<style lang="less" scoped>
.van-checkbox__label--disabled {
  color: #666;
}

.video-tan {
  width: 100%;
  height: 240px;
}

.wgdiv {
  width: 80px;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 5px;

  .wgb {
    background: #ffe8e8;
    color: #ff1414;
    display: inline-block;
    padding: 0 4px;
    border-radius: 2px;
  }

  .wgg {
    background: #eaf1ff;
    color: #0a5bfd;
    display: inline-block;
    padding: 0 4px;
    border-radius: 2px;
  }
}

.wgb {
  background: #ffe8e8;
  color: #ff1414;
}

.my_play {
  height: 80px;
  width: 80px;
  overflow: hidden;

  ::v-deep {
    .video-player {
      height: 100%;
      width: 100%;

      .video-js {
        video {
          object-fit: contain;
        }

        &.vjs-fluid {
          height: 80px !important;

        }

        .vjs-big-play-button {
          font-size: 45px;
        }
      }
    }

    .hide_video {
      video {
        display: none;
      }
    }
  }

}

.cimg {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.bimg {
  width: 25%;
  margin: 10px 10px;
  height: 90px;
}
.jsnames{
  font-size: 14px;
  color: #000;
  text-align: center;
  flex: 1;
  padding-bottom: 10px;
}
.bimg:nth-of-type(n + 2) {
  /* margin-left: 5%; */
}

#signature {
  z-index: 999;
}

.dakaimg {
  width: 24px;
  margin-right: 10px;
}

.ris {
  text-align: right;
  overflow: hidden;
}

.dis {
  display: inline-block;
}

.shijian {
  min-width: 115px;
  text-align: left;
}

.hedrowtop {
  background: #fff;
  padding: 6px 10px;
  font-size: 13px;
  color: #333;
  position: relative;
}

.hedrow {
  background: #fff;
  padding: 10px 10px;
  font-size: 13px;
  color: #333;
  position: relative;
}

.hedrows .van-row {
  background: #fff;
  padding: 10px 10px;
  font-size: 13px;
  color: #333;
  position: relative;
}

.qmimg {
  width: 60px;
  height: 30px;
  vertical-align: middle;
}

.heng {
  width: 30%;
  position: absolute;

  bottom: 0;
  /* left: 0; */
  margin-left: 10px;
  height: 2px;
  background: #2a9f58;
}

.cet {
  text-align: center;
}

.botdunw {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  margin-bottom: 60px;
  font-size: 12px;

  .diwdiv {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    width: 80%;
    background: #ededed;
    padding: 4px 0;
    border-radius: 2px;

    span {
      margin-left: 5px;
    }

    img {
      width: 15px;
    }
  }

}

.bottts {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  // height: 77px;

  // background: #fff;
  overflow: hidden;
  font-size: 14px;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: flex-end;

  .flxs {
    display: flex;
    align-items: flex-end;
    padding-left: 10px;
    color: #000;
    width: 50%;

    video {
      width: 80px;
      height: 80px;
      object-fit: fill;
    }



    img {
      width: 80px;
      height: 80px;
      display: block;
    }
  }

  .mend {
    height: 35px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #000;
    font-size: 12px;
  }

  .tyi {
    width: 100%;
    height: 42px;
    line-height: 42px;
    background-color: #2a9f58;
  }
}

.jiner {
  font-size: 22px;
  color: red;
  padding-left: 12px;
  text-align: left;

  /* font-weight: bold; */
}

.weixin {
  width: 100px;
  margin-right: 12px;
  display: block;
  margin-top: 8px;
}

.yizhifu {
  color: red;
  padding-right: 12px;
  font-size: 18px;
}

//----------------------------------------
.hestou {
  padding: 46px 0;
  height: 100%;
  background: #f7f9fc;
}

.mian {
  overflow: auto;
}

.van-list {
  padding-top: 46px;
}

.fixtan {
  width: 100%;
  height: 100%;

  .van-search {
    padding-top: 60px;
  }

  .foot_text {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    line-height: 45px;
    padding: 0 12px;
    border-bottom: 1px solid #f5f5f5;
  }
}

.dds {
  font-size: 14px;
  color: #2a9f58;
  text-align: center;
  margin-top: 15px;

  //   position: fixed;
  // width: 100%;
  // top: 50%;
  // transform:rotate(-25deg);
  .yidao {
    font-size: 16px;
    display: inline-block;
    padding: 10px 20px;
    color: red;
    font-weight: bold;
    border: 3px solid red;
    margin-top: 16px;
    line-height: 24px;
  }
}

.hs {
  padding: 20px 20px;
  text-indent: 2em;
}
</style>
<style lang="less">
.van-tabs__line {
  bottom: 20px;
}
</style>
